import React, { useState } from "react";
import { Wrapper } from "./SwapList.style";
import SwapSearch from "./SwapSearch/SwapSearch";
import SwapInfoList from "./SwapInfoList/SwapInfoList";
import { PaginationBox } from "components/Pagination/PaginationBox";
import SwapSummary from "./SwapSummary/SwapSummary";

// 브릿지 -> 스왑 :: (http://localhost:3000/super/bridge/swap)
const SwapList = () => {
  const [swapList, setSwapList] = useState({ list: [], summary: {} });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});
  const [isSearch, setIsSearch] = useState(false);

  return (
    <Wrapper>
      <div>
        <SwapSearch
          setSwapList={setSwapList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
          setIsSearch={setIsSearch}
        />

        <SwapSummary swapList={swapList} />

        <SwapInfoList
          swapList={swapList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
          setSwapList={setSwapList}
          isSearch={isSearch}
        />

        {isSearch && !!swapList.list.length && (
          <PaginationBox
            totalItemsCount={swapList.list[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default SwapList;
