import React, { useState } from "react";
import { Wrapper } from "./UserList.style";
import SuperWalletUserSearch from "./WalletUserSearch/SuperWalletUserSearch";
import SuperWalletUserDetail from "./WalletUserDetail/SuperWalletUserDetail";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 검색 -> 회원검색 :: (http://localhost:3000/super/search/user/wallet)
const SuperWalletUser = () => {
  const [superUserList, setSuperUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});
  const [isSearch, setIsSearch] = useState(false);

  return (
    <Wrapper>
      <div style={{ width: "80%" }}>
        <SuperWalletUserSearch
          setSuperUserList={setSuperUserList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
          setIsSearch={setIsSearch}
        />

        <SuperWalletUserDetail
          superUserList={superUserList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
          isSearch={isSearch}
        />

        {!!superUserList.length && (
          <PaginationBox
            totalItemsCount={superUserList[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default SuperWalletUser;
