import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import assetWalletColumn from "../assetWalletColumn.json";
import PageHandler from "components/PageHandler/PageHandler";
import { useToastCustom } from "hooks/useToastCustom";
import { POST_ASSET_LIST } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { ASSET_WALLET_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import AssetWalletDetailList from "./AssetWalletDetailList";
import EmptyScreen from "components/EmptyScreen/EmptyScreen";

// 페이 자산내역 :: 페이 자산내역 리스트 (http://localhost:3000/super/asset/wallet)
const AssetWalletDetail = ({
  setPage,
  setSize,
  assetWalletList,
  searchData,
  isSearch,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const { mutate } = useMutationCustom(POST_ASSET_LIST);

  const handleExcelDownload = () => {
    if (!isSearch || !assetWalletList.listCount) {
      toast("다운로드할 데이터가 없습니다", "error");
      return;
    }

    const data = {
      ...searchData,
      pageSize: assetWalletList.listCount,
      currentPage: 1,
    };

    mutate(data, {
      onSuccess: (response) => {
        const excelData = {
          data: response.list,
          headers: ASSET_WALLET_XLSX_DOWNLOAD.header,
          fileName: ASSET_WALLET_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>월렛 자산내역</List.Text>
        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {!isSearch && <ListDefault text={"페이 자산내역을"} />}
        {isSearch && !assetWalletList.list.length && <EmptyScreen />}
        {isSearch && !!assetWalletList.list.length && (
          <>
            <List.Content>
              {assetWalletColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {assetWalletList.list.map((v, idx) => (
                <AssetWalletDetailList key={idx} data={v} />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};

export default AssetWalletDetail;
