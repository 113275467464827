import {
  dateFormatDay,
  dateFormatEnd,
  dateFormatStart,
} from "components/newDateFormat";
import { PLACEHOLDER } from "constants/baseStyle";
import { POST_WALLET_USER_ADMIN_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";

// 검색 -> 회원검색 :: 회원 조회 (http://localhost:3000/super/search/user/wallet)
const SuperWalletUserSearch = ({
  setSuperUserList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
  setIsSearch,
}) => {
  const toast = useToastCustom();

  const [memberName, setMemberName] = useState("");
  const [memberCode, setMemberCode] = useState("");
  const [recommenderName, setRecommenderName] = useState("");
  const [recommenderCode, setRecommenderCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(dateFormatDay(new Date()));

  const { mutate } = useMutationCustom(POST_WALLET_USER_ADMIN_FETCH);

  // page, size 바뀔때
  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;

    const researchData = { ...searchData, pageSize, currentPage };

    mutate(researchData, {
      onSuccess: (data) => {
        setSuperUserList(data);
      },

      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  }, [currentPage, pageSize]);

  const handleSearch = () => {
    setPage(1);

    const searchCond = {
      memberName,
      memberCode,
      recommenderName,
      recommenderCode,
      mobile,
      email,
      walletAddress,
      fromDt: fromDt && dateFormatStart(fromDt),
      toDt: dateFormatEnd(toDt),
      pageSize,
      currentPage,
    };

    setSearchData(searchCond);

    mutate(searchCond, {
      onSuccess: (data) => {
        setSuperUserList(data);
        setIsSearch(true);
      },

      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>월렛 회원 조회</Search.Text>
      <Search.Container>
        <Search.Content>
          {/* 회원명 */}
          <Search.InputWrapper>
            <div>회원명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setMemberName(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 회원코드 */}
          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setMemberCode(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 추천인명 */}
          <Search.InputWrapper>
            <div>추천인명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setRecommenderName(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 추천인코드 */}
          <Search.InputWrapper>
            <div>추천인코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setRecommenderCode(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 전화번호 */}
          <Search.InputWrapper>
            <div>전화번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setMobile(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 이메일 */}
          <Search.InputWrapper>
            <div>이메일</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 지갑주소 */}
          <Search.InputWrapper style={{ width: "25rem" }}>
            <div>지갑주소</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setWalletAddress(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 가입일자 (부터) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>가입일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 가입일자 (까지) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>가입일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회버튼 */}
        <Search.Btn onClick={() => handleSearch()}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};

export default SuperWalletUserSearch;
