import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { POST_MIGRATION_FETCH } from "constants/pathConstant";
import { SWAP_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { useToastCustom } from "hooks/useToastCustom";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import MigrationDetailList from "./MigrationDetailList";
import migrationColumn from "../migrationColumn.json";
import { useMutationCustom } from "hooks/useMutationCustom";
import PageHandler from "components/PageHandler/PageHandler";
import { ListDefault } from "components/ListDefault/ListDefault";
import EmptyScreen from "components/EmptyScreen/EmptyScreen";

// 브릿지 -> 스왑 :: 스왑 신청/처리 목록 (http://localhost:3000/super/bridge/migration)
const MigrationDetail = ({
  setPage,
  setSize,
  migrationList,
  searchData,
  setMigrationList,
  isSearch,
}) => {
  const excelDownload = useExcelDownload();
  const toast = useToastCustom();

  const { mutate } = useMutationCustom(POST_MIGRATION_FETCH);
  // Excel 다운로드
  const handleExcelDownload = () => {
    if (!isSearch || !migrationList.list[0]?.cnt) {
      toast("다운로드할 데이터가 없습니다", "error");
      return;
    }

    const researchCond = {
      ...searchData,
      pageSize: migrationList.list[0]?.cnt,
      currentPage: 1,
    };

    mutate(researchCond, {
      onSuccess: (response) => {
        const excelData = {
          data: response.list,
          headers: SWAP_XLSX_DOWNLOAD.header,
          fileName: SWAP_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("데이터 전체 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.TextContainer>
          <List.Text>월렛 스왑 내역</List.Text>
        </List.TextContainer>

        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {/* {!isSearch ? (
          <ListDefault text={"스왑 내역을"} />
        ) : (
          <>
            <List.Content>
              {migrationColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {migrationList.list.map((v, idx) => (
                <MigrationDetailList
                  data={v}
                  key={idx}
                  searchData={searchData}
                  setMigrationList={setMigrationList}
                />
              ))}
            </Info.Wrapper>
          </>
        )} */}
        {!isSearch && <ListDefault text={"스왑 내역을"} />}
        {isSearch && !migrationList.list.length && <EmptyScreen />}
        {isSearch && !!migrationList.list.length && (
          <>
            <List.Content>
              {migrationColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {migrationList.list.map((v, idx) => (
                <MigrationDetailList
                  data={v}
                  key={idx}
                  searchData={searchData}
                  setMigrationList={setMigrationList}
                />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};

export default MigrationDetail;
