import Pagination from "react-js-pagination";
import { Wrapper } from "./PaginationBox.style";

// https://www.npmjs.com/package/react-js-pagination
export const PaginationBox = ({
  size = 10,
  totalItemsCount,
  page,
  setPage,
}) => {
  const handlePageChange = (page) => {
    setPage(page);
  };
  return (
    <Wrapper>
      <Pagination
        // 현제 보고있는 페이지
        activePage={Number(page)}
        // 한페이지에 출력할 아이템수
        itemsCountPerPage={Number(size)}
        // 총 아이템수
        totalItemsCount={Number(totalItemsCount)}
        // 표시할 페이지수
        pageRangeDisplayed={10}
        // onChange
        onChange={handlePageChange}
        firstPageText={"<<"}
        prevPageText={"<"}
        nextPageText={">"}
        lastPageText={">>"}
      />
    </Wrapper>
  );
};
