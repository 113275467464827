import { PLACEHOLDER } from "constants/baseStyle";
import { POST_MIGRATION_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Search, StateQuestion, Wrapper } from "template/SearchTemplate.style";
import migrationColumn from "../migrationColumn.json";
import { useOpenCustomModal } from "hooks/modal/useOpenCustomModal";
import ImgModal from "components/Modal/ImgModal";
import {
  dateFormatDay,
  dateFormatEnd,
  dateFormatStart,
} from "components/newDateFormat";

// 브릿지 -> 스왑 :: 스왑 검색 (http://localhost:3000/super/bridge/migration)
const MigrationSearch = ({
  setMigrationList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
  setIsSearch,
}) => {
  const toast = useToastCustom();
  const openStateModal = useOpenCustomModal(ImgModal);

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(dateFormatDay(new Date()));
  const [id, setId] = useState("");
  const [acctUserNm, setAcctUserNm] = useState("");
  const [acctCd, setAcctCd] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [state, setState] = useState("");
  const [txId, setTxId] = useState("");

  const { mutate } = useMutationCustom(POST_MIGRATION_FETCH);

  // page, size 바뀔때
  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;

    const researchData = { ...searchData, pageSize, currentPage };

    mutate(researchData, {
      onSuccess: (data) => {
        setMigrationList(data);
      },

      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  }, [currentPage, pageSize]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const data = {
      id,
      acctUserNm,
      fromDt: fromDt && dateFormatStart(fromDt),
      toDt: dateFormatEnd(toDt),
      acctCd,
      cellNumber,
      state,
      txId,
      pageSize,
      currentPage,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setMigrationList(data);
      },
      onError: () => {
        toast("데이터 조회 실패, 검색값을 확인해주세요.", "error");
      },
    });
  };

  const handleSearch = () => {
    setPage(1);
    setIsSearch(true);
    fetchData();
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  const handleStateModal = () => {
    openStateModal({ src: "/file/0f3daf0dswap-proccess.png" });
  };

  return (
    <Wrapper>
      <Search.Text>
        <span>월렛 스왑 검색</span>
        <StateQuestion onClick={handleStateModal}>?</StateQuestion>
      </Search.Text>
      <Search.Container style={{ gap: "24rem" }}>
        <Search.Content style={{ width: "100%" }}>
          {/* 번호 */}
          <Search.InputWrapper>
            <div>번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 회원명 */}
          <Search.InputWrapper>
            <div>회원명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setAcctUserNm(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 회원코드 */}
          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setAcctCd(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 전화번호 */}
          <Search.InputWrapper>
            <div>전화번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setCellNumber(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 상태 */}
          <Search.InputWrapper style={{ width: "18rem" }}>
            <div>상태</div>
            <Search.Select
              onChange={(e) => setState(e.target.value)}
              style={{ width: "16rem" }}
            >
              <Search.Option value="">{"전체"}</Search.Option>
              {Object.keys(migrationColumn.state).map((v) => (
                <Search.Option key={v} value={v}>
                  {migrationColumn.state[v]}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 지갑 주소 */}
          <Search.InputWrapper style={{ width: "38rem" }}>
            <div>txId</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setTxId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 신청일자 */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>신청일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 신청일자 */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>신청일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={handleSearch}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};

export default MigrationSearch;
