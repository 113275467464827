import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import { POST_WALLET_USER_ADMIN_FETCH } from "constants/pathConstant";
import { SUPER_WALLET_USER_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import userColumn from "./superUserColumn.json";
import PageHandler from "components/PageHandler/PageHandler";
import SuperWalletUserDetailList from "./SuperWalletUserDetailList";
import EmptyScreen from "components/EmptyScreen/EmptyScreen";

// 검색 -> 회원검색 :: 회원 목록 리스트 (http://localhost:3000/super/search/user/wallet)
const SuperWalletUserDetail = ({
  setPage,
  setSize,
  superUserList,
  searchData,
  isSearch,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const { mutate } = useMutationCustom(POST_WALLET_USER_ADMIN_FETCH);

  // Excel 다운로드
  const handleExcelDownload = () => {
    if (!superUserList[0]?.cnt)
      return toast("다운로드할 데이터가 없습니다", "error");

    const researchCond = {
      ...searchData,
      pageSize: superUserList[0]?.cnt,
      currentPage: 1,
    };

    mutate(researchCond, {
      onSuccess: (response) => {
        const excelData = {
          data: response,
          headers: SUPER_WALLET_USER_XLSX_DOWNLOAD.header,
          fileName: SUPER_WALLET_USER_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("데이터 전체 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>
          월렛 회원 목록
          {superUserList.length > 0 && (
            <span>(검색됨 : {superUserList[0]?.cnt}개)</span>
          )}
        </List.Text>
        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {!isSearch && <ListDefault text={"회원을"} />}
        {isSearch && !superUserList.length && <EmptyScreen />}
        {!!superUserList.length && (
          <>
            {/* List Column */}
            <List.Content>
              {userColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {superUserList?.map((v) => (
                <SuperWalletUserDetailList
                  key={v.id}
                  id={v.id}
                  createDate={v.createDate}
                  memberName={v.memberName}
                  memberCode={v.memberCode}
                  recommenderName={v.recommenderName}
                  recommenderCode={v.recommenderCode}
                  mobile={v.mobile}
                  email={v.email}
                  walletAddress={v.walletAddress}
                  state={v.state}
                  birthDay={v.birthDay}
                />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};

export default SuperWalletUserDetail;
