import { Info, List, Wrapper } from "template/ListTemplate.style";
import { Container } from "./SketchDropDetail.style";
import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import sketchDropColumn from "../sketchDropColumn.json";
import SketchDropDetailList from "./SketchDropDetailList";
import { PaginationBox } from "components/Pagination/PaginationBox";
import { useToastCustom } from "hooks/useToastCustom";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  HTTP_METHOD,
  POST_SKETCH_DROP_DETAIL,
  POST_SKETCH_DROP_DETAIL_SHEETS,
} from "constants/pathConstant";
import {
  SKETCH_DROP_DETAIL_HAVING_XLSX_DOWNLOAD,
  SKETCH_DROP_DETAIL_TOTAL_XLSX_DOWNLOAD,
  SKETCH_DROP_DETAIL_USED_XLSX_DOWNLOAD,
  SKETCH_DROP_DETAIL_XLSX_DOWNLOAD,
} from "constants/xlsxDownloadConstant";
import PageHandler from "components/PageHandler/PageHandler";
import { useExcelDownloadSheets } from "hooks/excelDownload/useExcelDownloadSheets";

// 정산 관리 -> 스케치 드랍 :: 상세 리스트  (http://localhost:3000/super/calculate/sketch-drop)
const SketchDropDetail = ({
  sketchDropDetailRes,
  detailPage,
  setDetailPage,
  searchDetailReq,
  detailSize,
  setDetailSize,
  sortOption,
  setSortOption,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();
  const excelDownloadDetail = useExcelDownloadSheets();

  const { mutate } = useMutationCustom(
    POST_SKETCH_DROP_DETAIL,
    HTTP_METHOD.POST
  );

  const { mutate: mutateDetailSheets } = useMutationCustom(
    POST_SKETCH_DROP_DETAIL_SHEETS,
    HTTP_METHOD.POST
  );

  const handleExcelDownload = () => {
    if (!sketchDropDetailRes[0]?.cnt)
      return toast("다운로드할 데이터가 없습니다", "error");

    const data = {
      ...searchDetailReq,
      pageSize: sketchDropDetailRes[0]?.cnt,
      currentPage: 1,
    };

    mutate(data, {
      onSuccess: (response) => {
        const excelData = {
          data: response.list,
          headers: SKETCH_DROP_DETAIL_XLSX_DOWNLOAD.header,
          fileName: SKETCH_DROP_DETAIL_XLSX_DOWNLOAD.filename,
        };
        excelDownload(excelData);
      },
      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  const handleExcelDownloadDetail = () => {
    if (!sketchDropDetailRes[0]?.cnt)
      return toast("다운로드할 데이터가 없습니다", "error");

    const data = {
      ...searchDetailReq,
      pageSize: sketchDropDetailRes[0]?.cnt,
      currentPage: 1,
    };

    mutateDetailSheets(data, {
      onSuccess: (response) => {
        const excelData = {
          sheets: [
            {
              data: response.totalList,
              headers: SKETCH_DROP_DETAIL_TOTAL_XLSX_DOWNLOAD.header,
              name: "총 분배 합산",
            },
            {
              data: response.havingList,
              headers: SKETCH_DROP_DETAIL_HAVING_XLSX_DOWNLOAD.header,
              name: "보유 기여도",
            },
            {
              data: response.usedList,
              headers: SKETCH_DROP_DETAIL_USED_XLSX_DOWNLOAD.header,
              name: "사용 기여도",
            },
          ],
          fileName: SKETCH_DROP_DETAIL_TOTAL_XLSX_DOWNLOAD.filename,
        };
        excelDownloadDetail(excelData);
      },
      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  return (
    <Container>
      <Wrapper>
        <List.TitleContainer>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <List.Text>연월 드랍 상세 리스트</List.Text>
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value={"txValue"}>합계순</option>
              <option value={"latest"}> 최신순</option>
            </select>
          </div>
          <List.BtnContainer>
            {/* 엑셀 다운로드 버튼 */}
            <PageHandler setPage={setDetailPage} setPageSize={setDetailSize} />
            <ExcelDownloadBtn onClick={handleExcelDownload} />
            <ExcelDownloadBtn
              onClick={handleExcelDownloadDetail}
              title="상세"
            />
          </List.BtnContainer>
        </List.TitleContainer>

        <List.Container>
          {sketchDropDetailRes.length === 0 ? (
            <ListDefault
              text={"좌측 '스케치 드랍 목록'에서 연월별 상세 목록을"}
            />
          ) : (
            <>
              <List.Content>
                {sketchDropColumn.detail.map((v, idx) => (
                  <List.Row $ratio={v.ratio} key={idx}>
                    {v.title}
                  </List.Row>
                ))}
              </List.Content>

              <Info.Wrapper>
                {sketchDropDetailRes.map((v, idx) => (
                  <SketchDropDetailList key={idx} data={v} />
                ))}
              </Info.Wrapper>
            </>
          )}
        </List.Container>
      </Wrapper>

      {sketchDropDetailRes.length !== 0 && (
        <PaginationBox
          totalItemsCount={sketchDropDetailRes[0]?.cnt}
          page={detailPage}
          setPage={setDetailPage}
          size={detailSize}
        />
      )}
    </Container>
  );
};

export default SketchDropDetail;
