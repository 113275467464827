import ExcelJS from "exceljs";
import { useToastCustom } from "hooks/useToastCustom";

export const useExcelDownloadSheets = () => {
  const toast = useToastCustom();

  return ({ sheets, fileName }) => {
    if (!sheets || !Array.isArray(sheets) || !fileName) {
      toast("엑셀 다운로드 실패 - 데이터 부족", "error");
      return;
    }

    try {
      const workbook = new ExcelJS.Workbook();

      // 각 워크시트를 생성하는 함수
      const createWorksheet = ({ name, headers, data }) => {
        if (!name || !headers || !data) {
          throw new Error(`Invalid sheet data: ${name || "No Name"}`);
        }

        const worksheet = workbook.addWorksheet(name);

        // 헤더 설정
        worksheet.columns = headers.map((h) => ({
          header: h.header,
          key: h.key,
        }));

        // 데이터 추가
        worksheet.addRows(data);

        // 가로 크기 동적 조정
        worksheet.columns.forEach((column) => {
          let maxDataLength = 0;
          column.eachCell({ includeEmpty: true }, (cell) => {
            const cellDataLength = (cell.text || "").toString().length;
            if (cellDataLength > maxDataLength) {
              maxDataLength = cellDataLength;
            }
          });
          column.width = maxDataLength * 1.5; // 약간의 여백 추가
        });

        // stateMemo 열의 너비를 40으로 고정
        const memoIndex = headers.findIndex((h) => h.key === "stateMemo");
        if (memoIndex !== -1) {
          worksheet.getColumn(memoIndex + 1).width = 40;
        }

        // 모든 셀에 스타일링 적용
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber === 1) {
            // 헤더 부분 스타일링
            row.eachCell((cell) => {
              cell.font = { bold: true };
            });
            row.height = 30; // 헤더 행의 높이를 여유롭게 설정
          } else {
            let maxCellLines = 0;
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.alignment = { vertical: "middle", wrapText: true };
              const cellLines = (cell.text || "").split("\n").length;
              if (cellLines > maxCellLines) {
                maxCellLines = cellLines;
              }

              // 홀수 줄 배경색 설정
              if (rowNumber % 2 === 0) {
                cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FFE0E0E0" }, // 옅은 회색
                };
              }
            });

            row.height = 15 * maxCellLines; // 가정: 한 줄당 15의 높이
          }
        });

        // 첫 번째 행 (헤더 행)을 고정
        worksheet.views = [{ state: "frozen", ySplit: 1 }];
      };

      // 각 워크시트를 추가
      sheets.forEach((sheet) => {
        createWorksheet(sheet);
      });

      // 파일 저장
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

      toast("액셀 다운로드 성공", "success");
    } catch (error) {
      console.error(error);
      toast("액셀 변환중 오류 발생", "error");
    }
  };
};
