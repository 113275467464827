import {
  dateFormatDay,
  dateFormatEnd,
  dateFormatStart,
} from "components/newDateFormat";
import { PLACEHOLDER } from "constants/baseStyle";
import { POST_PAYMENT_LIST } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import paymentWalletColumn from "../paymentWalletColumn.json";
import SearchWalletUserModal from "components/Modal/SearchWalletUserModal";
import SearchStoreModal from "components/Modal/SearchStoreModal";

// 결제 내역 :: 결제내역 조회 (http://localhost:3000/super/payment/wallet)
const PaymentWalletSearch = ({
  setPaymentWalletList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
  setIsSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUserSearch, setIsUserSearch] = useState(false);

  const [txTypeId, setTxTypeId] = useState("");
  const [txId, setTxId] = useState("");
  const [transactionFee, setTransactionFee] = useState("");
  const [coinType, setCoinType] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(dateFormatDay(new Date()));
  const [toWalletAddress, setToWalletAddress] = useState("");
  const [fromWalletAddress, setFromWalletAddress] = useState("");
  const [state, setState] = useState("");
  const [ssOrderId, setSsOrderId] = useState("");

  const [userData, setUserData] = useState({});

  const [storeData, setStoreData] = useState({});

  // useEffect(() => {
  //   setTxTypeId("");
  //   setTxId("");
  //   setTransactionFee("");
  //   setFromDt("");
  //   setToDt(dateFormatEnd(new Date()));
  //   setToWalletAddress("");
  //   setFromWalletAddress("");
  //   setState("");
  // }, [storeData]);

  const { mutate } = useMutationCustom(POST_PAYMENT_LIST);

  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;
    const researchData = { ...searchData, currentPage, pageSize };
    setSearchData(researchData);

    mutate(researchData, {
      onSuccess: (data) => {
        setPaymentWalletList(data);
      },
    });
  }, [pageSize, currentPage]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const data = {
      ssCd: storeData.storeCd || "",
      memberName: userData.memberName || "",
      memberCode: userData.memberCode || "",
      txTypeId,
      txId,
      transactionFee,
      coinType,
      fromDt: fromDt && dateFormatStart(fromDt),
      toDt: dateFormatEnd(toDt),
      pageSize,
      currentPage,
      //TODO: 여기부터 추가
      state,
      toWalletAddress,
      fromWalletAddress,
      ssOrderId,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setPaymentWalletList(data);
      },
    });
  };

  const handleSearch = () => {
    setPage(1);
    setIsSearch(true);
    fetchData();
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>월렛 결제내역 조회</Search.Text>
      <Search.Container>
        <Search.Content style={{ width: "90rem" }}>
          {/* 회원명 */}
          <Search.InputWrapper
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsUserSearch((prev) => !prev);
            }}
          >
            <div>회원명</div>
            <Search.Input
              style={{ cursor: "pointer" }}
              placeholder={"눌러서 조회"}
              onKeyUp={(e) => handleEnter(e.key)}
              value={userData.memberName}
              readOnly
            />
          </Search.InputWrapper>

          {/* 회원코드 */}
          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder={"자동 입력"}
              onKeyUp={(e) => handleEnter(e.key)}
              value={userData.memberCode}
              disabled
            />
          </Search.InputWrapper>

          {/* 가맹점명 */}
          <Search.InputWrapper
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <div>가맹점명</div>
            <Search.Input
              style={{ cursor: "pointer" }}
              placeholder="눌러서 조회"
              readOnly
              value={storeData.storeNm}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 가맹점코드 */}
          <Search.InputWrapper>
            <div>가맹점 코드</div>
            <Search.Input
              placeholder="자동 입력"
              onKeyUp={(e) => handleEnter(e.key)}
              disabled
              value={storeData.storeCd}
            />
          </Search.InputWrapper>

          {/* txId */}
          <Search.InputWrapper>
            <div>txId</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setTxId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
              value={txId}
            />
          </Search.InputWrapper>

          {/* 보낸지갑주소 */}
          <Search.InputWrapper>
            <div>보낸 지갑주소</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setFromWalletAddress(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
              value={fromWalletAddress}
            />
          </Search.InputWrapper>

          {/* 받은지갑주소 */}
          <Search.InputWrapper>
            <div>받은 지갑주소</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setToWalletAddress(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
              value={toWalletAddress}
            />
          </Search.InputWrapper>

          {/* 일자 (부터) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 일자 (까지) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 결제 코인 타입 */}
          <Search.InputWrapper>
            <div>결제 코인 타입</div>
            <Search.Select onChange={(e) => setCoinType(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              {Object.keys(paymentWalletColumn.coinType).map((v, idx) => (
                <Search.Option key={idx} value={v}>
                  {paymentWalletColumn.coinType[v]}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 유형 */}
          <Search.InputWrapper>
            <div>유형</div>
            <Search.Select onChange={(e) => setState(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              {paymentWalletColumn.state.map((v, idx) => (
                <Search.Option key={idx} value={v}>
                  {v}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 상태 */}
          <Search.InputWrapper>
            <div>상태</div>
            <Search.Select onChange={(e) => setTxTypeId(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              {Object.keys(paymentWalletColumn.txTypeId).map((v, idx) => (
                <Search.Option key={idx} value={v}>
                  {paymentWalletColumn.txTypeId[v]}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 주문번호 */}
          <Search.InputWrapper>
            <div>주문번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setSsOrderId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
              value={ssOrderId}
            />
          </Search.InputWrapper>
        </Search.Content>
        <Search.Btn onClick={() => handleSearch()}>조회</Search.Btn>
      </Search.Container>

      {isOpen && (
        <SearchStoreModal setIsOpen={setIsOpen} setStoreData={setStoreData} />
      )}

      {isUserSearch && (
        <SearchWalletUserModal
          setIsOpen={setIsUserSearch}
          setUserData={setUserData}
        />
      )}
    </Wrapper>
  );
};

export default PaymentWalletSearch;
