import React, { useState } from "react";
import { Wrapper } from "./AssetWallet.style.js";
import AssetWalletSearch from "./AssetWalletSearch/AssetWalletSearch.jsx";
import AssetWalletSummary from "./AssetWalletSummary/AssetWalletSummary.jsx";
import AssetWalletDetail from "./AssetWalletDetail/AssetWalletDetail.jsx";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 페이 자산내역 :: (http://localhost:3000/super/asset/wallet)
const AssetWallet = () => {
  const [assetWalletList, setAssetWalletList] = useState({
    summary: {},
    list: [],
    listCount: 0,
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});
  const [isSearch, setIsSearch] = useState(false);

  return (
    <Wrapper>
      <div>
        <AssetWalletSearch
          setAssetWalletList={setAssetWalletList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
          setIsSearch={setIsSearch}
        />

        {/* {assetWalletList.summary && (
          <AssetWalletSummary assetWalletList={assetWalletList.summary} />
        )} */}

        <AssetWalletDetail
          assetWalletList={assetWalletList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
          isSearch={isSearch}
        />

        {isSearch && !!assetWalletList.listCount && (
          <PaginationBox
            totalItemsCount={assetWalletList.listCount}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default AssetWallet;
