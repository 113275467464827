import { IMG_CDN_URL } from "constants/pathConstant";
import { EmptyScreenStyle } from "./EmptyScreen.style";

/**
 *
 * @param {string} height
 * @param {string} text
 * @param {string} fontSize
 * @param {string} imgWidth
 * @param {string} imgHeight
 * @returns
 */
const EmptyScreen = ({
  height = "30rem",
  text = "검색 결과가 없습니다.",
  fontSize,
  imgWidth,
  imgHeight,
}) => {
  return (
    <EmptyScreenStyle.Wrapper height={height}>
      <EmptyScreenStyle.Img
        src={`${IMG_CDN_URL}/file/a7242861empty_box.svg`}
        width={imgWidth}
        height={imgHeight}
      />
      <EmptyScreenStyle.Text fontSize={fontSize}> {text}</EmptyScreenStyle.Text>
    </EmptyScreenStyle.Wrapper>
  );
};

export default EmptyScreen;
