import { ExcelBtn } from "./Btn.style";

export const ExcelDownloadBtn = ({ onClick, title = "엑셀파일 다운로드" }) => {
  return (
    <ExcelBtn.Wrapper onClick={onClick}>
      <ExcelBtn.Logo src="/assets/imgs/btn_excel_download.svg" />
      <ExcelBtn.Text>{title}</ExcelBtn.Text>
    </ExcelBtn.Wrapper>
  );
};
