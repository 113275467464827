import { POST_WALLET_ADDRESS } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import SearchWalletUserModal from "components/Modal/SearchWalletUserModal";

// 페이 자산내역 :: 자산내역 조회 (http://localhost:3000/super/asset/wallet)
const WalletAddressSearch = ({ setWalletAddressList, setIsSearch }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [userData, setUserData] = useState({});

  const { mutate } = useMutationCustom(POST_WALLET_ADDRESS);

  const fetchData = () => {
    const data = {
      memberId: userData.id,
    };

    mutate(data, {
      onSuccess: (data) => {
        setWalletAddressList(data);
      },
    });
  };

  const handleSearch = () => {
    if (!userData.id) {
      return alert("회원을 선택하세요!");
    }
    fetchData();
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>지갑 생성 여부 조회</Search.Text>
      <Search.Container>
        <Search.Content>
          {/* 회원명 */}
          <Search.InputWrapper
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <div>회원명</div>
            <Search.Input
              style={{ cursor: "pointer" }}
              placeholder="눌러서 조회"
              onKeyUp={(e) => handleEnter(e.key)}
              readOnly
              value={userData.memberName}
            />
          </Search.InputWrapper>

          {/* 회원코드 */}
          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder="자동 입력"
              onKeyUp={(e) => handleEnter(e.key)}
              disabled
              value={userData.memberCode}
            />
          </Search.InputWrapper>

          {/* 전화번호 */}
          <Search.InputWrapper>
            <div>전화번호</div>
            <Search.Input
              placeholder="자동 입력"
              onKeyUp={(e) => handleEnter(e.key)}
              disabled
              value={userData.mobile}
            />
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={() => handleSearch()}>조회</Search.Btn>
      </Search.Container>

      {isOpen && (
        <SearchWalletUserModal
          setIsOpen={setIsOpen}
          setUserData={setUserData}
        />
      )}
    </Wrapper>
  );
};

export default WalletAddressSearch;
